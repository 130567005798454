@font-face {
  font-family: 'EDNimpkishRegular';
  src: url('../fonts/EDNimpkish-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'BricolageGrotesque';
  src: url('../fonts/BricolageGrotesque-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

// Stuff we can't do with Ant Component Design Tokens
.ant-skeleton-paragraph {
  margin: 0;
  padding: 0;
}

.ant-card-body p:last-child {
  margin-bottom: 0;
}

.ant-btn {
  text-transform: lowercase;
}

.list-display {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.ant-table .anticon {
  font-size: 17px;
}

.ant-btn.ant-btn-danger {
  background-color: #FF4150;
  border-color: #FF4150;
}

.ant-menu-item-selected .ant-menu-title-content {
  color: black;
}

.ant-menu-title-content {
  transition: color 0s !important;

  a * {
    transition: color 0s !important;
  }
}

.ant-tabs-content .ant-card {
  box-shadow: none;
}
