body {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif !important;
}

/* general */
.error-message {
  display: block;
  color: red;
  line-height: 1.7;
  margin-bottom: 20px;
}
